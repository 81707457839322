import { useEffect, useState, createContext } from "react"
import cx from "classnames"
import LoadingIcon from "../../icons/LoadingIcon"
import YoutubeSearchInput from "./YoutubeSearchInput"
import YoutubeVideoPlayer from "./YoutubeVideoPlayer"
import LongRunningProcess from "../../shared/utils/LongRunningProcess"
import SongsContainer from "../../shared/components/SongsContainer"
import groupBySong from "../../shared/utils/songNormalizer"
import { YOUTUBE_LINK_REGEX } from "../../shared/utils/helpers"

export const SimilaritySearchContext = createContext()

const YoutubeSearchContainer = () => {
  const el = document.getElementById("mm-youtube-similarity-container")
  const user_role = el && el.getAttribute("data-user-role")
  const [searchQueryTerm, setSearchQueryTerm] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [songs, setSongs] = useState([])

  const contextValue = {
    searchQueryTerm,
    setSearchQueryTerm,
  }

  const handleSuccess = (data) => {
    let { songs } = data
    console.log(data)
    setIsProcessing(false)
    const groupedSongs = groupBySong(songs)
    setSongs(groupedSongs)
  }

  useEffect(() => {
    if (YOUTUBE_LINK_REGEX.test(`v=${searchQueryTerm}`)) {
      setIsProcessing(true)
    } else {
      setIsProcessing(false)
    }
  }, [searchQueryTerm])

  return (
    <>
      <SimilaritySearchContext.Provider value={contextValue}>
        <section className={cx("search-wrap")} aria-label="Search">
          <nav className="tabs desktop" aria-label="Browse by Search & Filters or Youtube Link">
            <ul>
              <li className="">
                <a href="/browse">Search & Browse</a>
              </li>
              <li className="current">
                <a data-prevent-default>
                  <span className="new">Search by Youtube Link (beta)</span>
                </a>
              </li>
            </ul>
          </nav>
          <YoutubeSearchInput />
        </section>
        {searchQueryTerm && <YoutubeVideoPlayer videoId={searchQueryTerm} />}
        {isProcessing && (
          <LongRunningProcess
            url={`/youtube-search/${searchQueryTerm}`}
            pollInterval={1000}
            timeout={100000}
            callback={handleSuccess}
          >
            <LoadingIcon />
          </LongRunningProcess>
        )}

        {/** Show songs here */}
        {songs[0] && !isProcessing && <SongsContainer songs={songs} user_role={user_role} />}
        {songs.length === 0 && !isProcessing && <div id="empty-search-wrapper"></div>}
      </SimilaritySearchContext.Provider>
    </>
  )
}

export default YoutubeSearchContainer
