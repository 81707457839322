import React from "react"
import ReactDOM from "react-dom"
import axios from "axios"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  concat,
} from "@apollo/client"
import "../../stylesheets/index.scss"

// components
import AccountContainer from "./pages/account/AccountContainer"
import AnthologyContainer from "./pages/anthology/AnthologyContainer"
import ArtistCollabsContainer from "./pages/artist_collabs/ArtistCollabsContainer"
import ArtistResidencyContainer from "./pages/artist_residency/ArtistResidencyContainer"
import ArtistsContainer from "./pages/artists/ArtistsContainer"
import BrowseBetaContainer from "./pages/browse/BrowseBetaContainer"
import BrowseFeaturedMixtapesContainer from "./pages/browse_featured_mixtapes/BrowseFeaturedMixtapesContainer"
import CheckoutContainer from "./pages/checkout/CheckoutContainer"
import ContactContainer from "./pages/contact/ContactContainer"
import CollaborationsContainer from "./pages/collaborations/CollaborationsContainer"
import CustomRequestFormContainer from "./pages/custom_request/CustomRequestFormContainer"
import CareersContainer from "./pages/careers/CareersContainer"
import DownloadSongVersions from "./pages/download_song_versions/DownloadSongVersions"
import GivingBackContainer from "./pages/giving_back/GivingBackContainer"
import HelpContainer from "./pages/help/HelpContainer"
import HomeContainer from "./pages/home/HomeContainer"
import LabelPartnershipsContainer from "./pages/label_partnerships/LabelPartnershipsContainer"
import MixtapesContainer from "./pages/mixtapes/MixtapesContainer"
import MusicLicensingContainer from "./pages/music_licensing/MusicLicensingContainer"
import OrderHistoryContainer from "./pages/order_history/OrderHistoryContainer"
import OurWorkContainer from "./pages/our_work/OurWorkContainer"
import PrismicTestContainer from "./pages/prismic_test/PrismicTestContainer"
import PrismicDevTestContainer from "./pages/prismic_dev_test/PrismicDevTestContainer"
import ProjectSearchContainer from "./pages/project_search/ProjectSearchContainer"
import PurposeContainer from "./pages/purpose/PurposeContainer"
import PrismicPreviewEndpoint from "./pages/prismic_preview/PrismicPreviewEndpoint"
import PrismicDraftContainer from "./pages/prismic_draft/PrismicDraftContainer"
import PodcastLicenseContainer from "./pages/podcast_license/PodcastLicenseContainer"
import ReelLicenseContainer from "./pages/reel_license/ReelLicenseContainer"
import StudioContainer from "./pages/studio/StudioContainer"
import SongsContainer from "./pages/song_version/SongsContainer"
import StyleGuideContainer from "./pages/style_guide/StyleGuideContainer"
import SubmissionsFormContainer from "./pages/submissions/SubmissionsFormContainer"
import SelectBlanketOrderModal from "./shared/components/SelectBlanketOrderModal"
import TeamContainer from "./pages/team/TeamContainer"
import WhatWeDoContainer from "./pages/what_we_do/WhatWeDoContainer"
import WeddingLicenseContainer from "./pages/wedding_license/WeddingLicenseContainer"
import ArtistPageContainer from "./pages/artist/ArtistPageContainer"
import SongVersionsContainer from "./pages/song_version/SongVersionsContainer"
import SongVersionPageContainer from "./pages/song_version/SongVersionPageContainer"
import ReceiptNextSteps from "./pages/receipt/ReceiptNextSteps"
import MixtapePageContainer from "./pages/mixtape/MixtapePageContainer"
import YoutubeSearchContainer from "./pages/you_tube_track/YoutubeSearchContainer"

const csrfArr = []

const httpLink = createHttpLink({
  uri: "/api/graphql",
})

const csrfToken =
  window._rails_env == "test" ? "" : document.querySelector('meta[name="csrf-token"]').content

let authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-CSRF-Token": csrfToken,
    },
  })

  return forward(operation)
})

// TODO: Worth looking into using ApolloClient as a state management solution.
// Looks to persist between pages and is singleton.
// NOTE: Likely wouldn't persist between page if it weren't for backbone hijacking navigation.
let client = new ApolloClient({
  // connectToDevTools: true, //uncomment if you want to connect with apollo client on dev
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
})

axios.defaults.headers.common["X-CSRF-Token"] = csrfToken

const containers = [
  { component: <AccountContainer />, elementId: "mm-account-container" },
  { component: <AnthologyContainer />, elementId: "mm-anthology-container" },
  { component: <ArtistCollabsContainer />, elementId: "mm-artist-collabs-container" },
  { component: <ArtistResidencyContainer />, elementId: "mm-artist-residency-container" },
  { component: <ArtistsContainer />, elementId: "mm-artists-container" },
  { component: <ArtistPageContainer />, elementId: "mm-artist-page-container" },
  { component: <BrowseBetaContainer />, elementId: "mm-browse-beta-container" },
  {
    component: <BrowseFeaturedMixtapesContainer />,
    elementId: "mm-browse-featured-mixtapes-container",
  },
  { component: <CheckoutContainer />, elementId: "mm-checkout-container" },
  { component: <ContactContainer />, elementId: "mm-contact-container" },
  { component: <CollaborationsContainer />, elementId: "mm-collaborations-container" },
  { component: <CustomRequestFormContainer />, elementId: "mm-custom-request" },
  { component: <CareersContainer />, elementId: "mm-careers-container" },
  { component: <DownloadSongVersions />, elementId: "mm-download-song-versions" },
  { component: <GivingBackContainer />, elementId: "mm-giving-back-container" },
  { component: <LabelPartnershipsContainer />, elementId: "mm-label-partnerships-container" },
  { component: <HelpContainer />, elementId: "mm-help-container" },
  { component: <HomeContainer />, elementId: "home-new" },
  { component: <MixtapePageContainer />, elementId: "mm-mixtape-page-container" },
  { component: <MixtapesContainer />, elementId: "mm-mixtapes-container" },
  { component: <MusicLicensingContainer />, elementId: "mm-music-licensing-container" },
  { component: <OrderHistoryContainer />, elementId: "mm-order-history-container" },
  { component: <OurWorkContainer />, elementId: "mm-our-work-container" },
  { component: <PrismicTestContainer />, elementId: "mm-prismic-test-container" },
  { component: <PrismicDevTestContainer />, elementId: "mm-prismic-dev-test-container" },
  { component: <PrismicPreviewEndpoint />, elementId: "mm-prismic-preview-endpoint" },
  { component: <PrismicDraftContainer />, elementId: "mm-prismic-draft-container" },
  { component: <ProjectSearchContainer />, elementId: "mm-project-search-container" },
  { component: <PurposeContainer />, elementId: "mm-purpose-container" },
  { component: <PodcastLicenseContainer />, elementId: "mm-podcast-license-container" },
  { component: <ReceiptNextSteps />, elementId: "mm-next-steps-container" },
  { component: <ReelLicenseContainer />, elementId: "mm-reel-license-container" },
  { component: <SongsContainer />, elementId: "mm-similar-songs-container" },
  { component: <SongVersionsContainer />, elementId: "mm-song-versions-container" },
  { component: <SongVersionPageContainer />, elementId: "mm-song-version-page-container" },
  { component: <StudioContainer />, elementId: "mm-studio-container" },
  { component: <StyleGuideContainer />, elementId: "mm-style-guide-container" },
  { component: <SubmissionsFormContainer />, elementId: "mm-submissions" },
  { component: <SelectBlanketOrderModal />, elementId: "mm-select-blanket-order-modal" },
  { component: <TeamContainer />, elementId: "mm-team-container" },
  { component: <WhatWeDoContainer />, elementId: "mm-what-we-do-container" },
  { component: <WeddingLicenseContainer />, elementId: "mm-wedding-license-container" },
  { component: <YoutubeSearchContainer />, elementId: "mm-youtube-similarity-container" },
]

// initial mounting of components
containers.forEach((container) => {
  if (document.getElementsByClassName("server-rendered-content")[0])
    document.getElementsByClassName("server-rendered-content")[0].style.display = "none"
  if (container.elementId === "mm-select-blanket-order-modal") {
    const elements = Array.from(document.querySelectorAll("#mm-select-blanket-order-modal"))
    if (elements === null) return
    elements.map((element) => {
      const svid = element.getAttribute("svid")
      const App = () => {
        return (
          <ApolloProvider client={client}>
            <SelectBlanketOrderModal svid={svid} />
          </ApolloProvider>
        )
      }

      ReactDOM.render(<App />, element)
    })
  } else {
    const element = document.getElementById(container.elementId)
    if (element === null) return

    const App = () => {
      return <ApolloProvider client={client}>{container.component}</ApolloProvider>
    }

    ReactDOM.render(<App />, element)
  }
})

const observeDOM = (function () {
  const MutationObserver = window.MutationObserver,
    eventListenerSupported = window.addEventListener

  return function (obj, callback) {
    if (MutationObserver) {
      const obs = new MutationObserver((mutations) => {
        if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) callback()
      })
      obs.observe(obj, { childList: true })
    } else if (eventListenerSupported) {
      obj.addEventListener("DOMNodeInserted", callback, false)
      obj.addEventListener("DOMNodeRemoved", callback, false)
    }
  }
})()

const observeBrowse = (function () {
  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver,
    eventListenerSupported = window.addEventListener

  return function (obj, callback) {
    if (MutationObserver) {
      const obs = new MutationObserver((mutations) => {
        if (
          mutations[0].attributeName === "data-count" ||
          mutations[0].attributeName === "data-display-count" ||
          mutations[0].attributeName === "data-browse-results"
        ) {
          callback()
        }
      })

      obs.observe(obj, { attributes: true })
    } else if (eventListenerSupported) {
      obj.addEventListener("DOMAttrModified", callback, false)
    }
  }
})()

const resetBrowseResults = () => {
  const el = document.getElementById("mm-song-versions-table-result-info")
  el.setAttribute("data-count", "0")
  el.setAttribute("data-display-count", "0")
  el.setAttribute("data-browse-results", '{"result":[]}')
}

const rerenderPage = (component, element) => {
  resetHeaders()

  const App = () => {
    return <ApolloProvider client={client}>{component}</ApolloProvider>
  }

  ReactDOM.render(<App />, element)
  reattachLinks()
}

// update csrf headers in Axios and Apollo when user logs in/out
const resetHeaders = () => {
  if (window._rails_env !== "test" && !csrfArr.includes(mm.csrf)) {
    csrfArr.push(mm.csrf)
    axios.defaults.headers.common["X-CSRF-Token"] = mm.csrf

    authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: { "X-CSRF-Token": mm.csrf },
      })
      return forward(operation)
    })

    client = new ApolloClient({
      link: concat(authMiddleware, httpLink),
      cache: new InMemoryCache(),
    })
  }
}

const reattachLinks = () => {
  // reattach link click handlers from marmoset_music.js:280
  const links = document.querySelectorAll('a[data-bypass="true"]')
  links.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault()
      const href = link.getAttribute("href")
      window.mm.router.navigate(href, { trigger: true })
    })
  })

  const preventLinks = document.querySelectorAll("a[data-prevent-default]")
  preventLinks.forEach((link) => {
    ;["click", "hover"].forEach((eventType) => {
      link.addEventListener(eventType, (e) => e.preventDefault)
    })
  })
}

// rerenders components on subsequent page change
observeDOM(document.getElementById("content"), () => {
  containers.forEach((container) => {
    // for when the page is not reloaded but redirected within the app
    if (document.getElementsByClassName("server-rendered-content")[0])
      document.getElementsByClassName("server-rendered-content")[0].style.display = "none"

    if (container.elementId === "mm-select-blanket-order-modal") {
      const elements = Array.from(document.querySelectorAll("#mm-select-blanket-order-modal"))
      if (elements === null) return
      elements.map((element) => {
        const svid = element.getAttribute("svid")
        rerenderPage(<SelectBlanketOrderModal svid={svid} />, element)
      })
    } else {
      const element = document.getElementById(container.elementId)

      if (element !== null && element.childElementCount === 0) {
        if (container.elementId !== "mm-browse-container") {
          resetBrowseResults()
        }

        rerenderPage(container.component, element)
      }
    }
    mm.drawer._buildCSS() // prevents drawer menu from hiding behind scrollbar
  })
})
