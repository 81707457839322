import cx from "classnames"
import { useRefinementList } from "react-instantsearch-hooks-web"
import { useWorkbenchStore } from "../../store/store"
import { transformFlags } from "./FilterTransforms"

const FlagRefinementListItem = ({ item, refine }) => {
  const flagsToggledOn = useWorkbenchStore((state) => state.flagsToggledOn)
  const addToFlagsOn = useWorkbenchStore((state) => state.addToFlagsOn)
  const removeFromFlagsOn = useWorkbenchStore((state) => state.removeFromFlagsOn)

  const handleClick = (e) => {
    e.preventDefault()
    if (!flagsToggledOn.includes(item.value)) {
      addToFlagsOn(item.value)
      refine(item.value)
    } else {
      removeFromFlagsOn(item.value)
      refine(item.value)
    }
  }

  return (
    <li
      data-val={item.label}
      data-cy={`internal-option-${item.value}`}
      data-pretty={`${item.label} Flag`}
      className={cx(
        "actionable",
        item.label.toLowerCase(),
        flagsToggledOn.includes(item.value) ? "selected" : ""
      )}
    >
      {" "}
      <a onClick={handleClick}>{item.label}</a>
    </li>
  )
}

const FlagsRefinementList = (props) => {
  const { items, refine } = useRefinementList(props)

  return (
    <ul>
      {items.map((item, index) => (
        <FlagRefinementListItem key={index} item={item} refine={refine} />
      ))}
    </ul>
  )
}

const WorkbenchFilterInternalBeta = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <FlagsRefinementList
        attribute="internal_flags"
        operator="or"
        transformItems={transformFlags}
      />
    </fieldset>
  )
}

export default WorkbenchFilterInternalBeta
