import { useWorkbenchStore } from "../../store/store"
import cx from "classnames"
import FilterMLogo from "../../icons/FilterMLogo"
import FilterHelpIcon from "../../icons/FilterHelpIcon"
import useCheckMobileScreen from "../../shared/utils/useCheckMobileScreens"
import { navigationItems } from "./FilterTransforms"
import { useActiveFilters } from "../../shared/utils/useActiveFilters"
import { useEffect, useRef } from "react"
import SortIcon from "../../icons/SortIcon"

const WorkbenchNavLink = ({ link, handleFilterNavClick, isInternalUser }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const activeFilters = useActiveFilters()
  const isMobile = useCheckMobileScreen()

  // If is internal: show everything Otherwise (OR) show everything other than "internal" & "customizable" links
  if (isInternalUser || (link.value !== "internal" && link.value !== "customizable")) {
    return (
      <li className={link.value}>
        <a
          href=""
          className={cx(
            "mm-filter-link",
            link.value == currentFilter ? "current" : "",
            activeFilters.includes(link.value) ? "set" : ""
          )}
          onClick={handleFilterNavClick}
          data-value={link.value}
          data-cy={link.value + "-button"}
        >
          {link.value === "internal" ? (
            <span className="icon" data-value="internal">
              <FilterMLogo />
            </span>
          ) : link.value === "sort" && !isMobile ? (
            <span className="icon" data-value="sort">
              <label className="sort-icon-label" data-value="sort">
                Sort by
              </label>
              <SortIcon />
            </span>
          ) : link.value === "arc" ? (
            `${link.label}™`
          ) : (
            link.label
          )}
        </a>
      </li>
    )
  } else {
    return null
  }
}

const WorkbenchNav = ({ user_role }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const setCurrentFilter = useWorkbenchStore((state) => state.setCurrentFilter)
  const isInternalUser = user_role === "internal"
  const isMobile = useCheckMobileScreen()
  const infoRef = useRef(null)

  useEffect(() => {
    if (isMobile && currentFilter === "") {
      setCurrentFilter("vocals")
    }
  }, [isMobile])

  const handleFilterNavClick = (e) => {
    e.preventDefault()
    if (currentFilter === e.target.dataset.value) {
      setCurrentFilter("")
      e.target.blur()
    } else {
      setCurrentFilter(e.target.dataset.value)
    }
  }

  const handleInfoClick = (e) => {
    e.preventDefault()
    let cancel
    var targ = infoRef.current.getAttribute("href")
    var container = document.getElementById("mm-filter-info")
    if (container.classList.contains("showing")) {
      cancel = false
      infoRef.current.classList.remove("active")
      container.classList.remove("showing")
      container.querySelector(targ).classList.remove("showing")
      setTimeout(function () {
        if (cancel) return
        container.classList.remove("visible")
      }, 150)
    } else {
      var z = 4
      cancel = true
      container.querySelector(targ).classList.add("showing")
      infoRef.current.classList.add("showing")
      container.classList.add("visible")
      container.classList.add("showing")
      infoRef.current.style.zIndex = z
      infoRef.current.classList.add("active")
    }
  }

  return (
    <nav className="filter" aria-label="Filter options">
      <span id="filter-options-label" style={{ display: "none" }}>
        Filter options
      </span>

      {/* Mobile navigation */}
      <select
        id="filter-select"
        aria-labelledby="filter-options-label"
        onChange={(e) => setCurrentFilter(e.target.value)}
      >
        <option value="vocals">Vocals</option>
        <option value="mood">Mood</option>
        <option value="energy">Energy</option>
        <option value="arc">Arc&trade;</option>
        <option value="length">Length</option>
        <option value="instruments">Instruments</option>
        <option value="genres">Genres</option>
        {isInternalUser && <option value="customizable">Customizable</option>}
        <option value="sort">Sort</option>
      </select>

      {/* Desktop navigation */}
      <ul className={cx(isInternalUser && "internal")}>
        {Object.keys(navigationItems).map((key, index) => (
          <WorkbenchNavLink
            key={index}
            link={navigationItems[key]}
            handleFilterNavClick={handleFilterNavClick}
            isInternalUser={isInternalUser}
          />
        ))}
      </ul>

      <div className="mm-connect-help"></div>
      <a
        ref={infoRef}
        data-prevent-default
        className={cx("info", "mm-hover-connect", currentFilter === "" && "not-active")}
        data-connect="help"
        data-connect-text="Help"
        href={"#" + currentFilter}
        onClick={handleInfoClick}
      >
        <div className="icon">
          <FilterHelpIcon />
        </div>
      </a>
    </nav>
  )
}

export default WorkbenchNav
