import { internal_flags, navigationItems } from "../../pages/browse/FilterTransforms"
import { useWorkbenchStore } from "../../store/store"

export const useActiveFilters = () => {
  const arcsToggledOn = useWorkbenchStore((state) => state.arcsToggledOn)
  const energiesToggledOn = useWorkbenchStore((state) => state.energiesToggledOn)
  const genresToggledOn = useWorkbenchStore((state) => state.genresToggledOn)
  const genresToggledOff = useWorkbenchStore((state) => state.genresToggledOff)
  const instrumentsToggledOn = useWorkbenchStore((state) => state.instrumentsToggledOn)
  const instrumentsToggledOff = useWorkbenchStore((state) => state.instrumentsToggledOff)
  const moodsToggledOn = useWorkbenchStore((state) => state.moodsToggledOn)
  const moodsToggledOff = useWorkbenchStore((state) => state.moodsToggledOff)
  const flagsToggledOn = useWorkbenchStore((state) => state.flagsToggledOn)
  const instrumentalToggledOn = useWorkbenchStore((state) => state.instrumentalToggledOn)
  const vocalsToggledOn = useWorkbenchStore((state) => state.vocalsToggledOn)
  const vocalsFemaleToggledOn = useWorkbenchStore((state) => state.vocalsFemaleToggledOn)
  const vocalsMaleToggledOn = useWorkbenchStore((state) => state.vocalsMaleToggledOn)
  const artistCollabSelection = useWorkbenchStore((state) => state.artistCollabSelection)
  const customizableSelection = useWorkbenchStore((state) => state.customizableSelection)
  const sortedIndexName = useWorkbenchStore((state) => state.sortedIndexName)
  const fervorSelection = useWorkbenchStore((state) => state.fervorSelection)
  const forMarmosetSelection = useWorkbenchStore((state) => state.forMarmosetSelection)
  const notForTVSelection = useWorkbenchStore((state) => state.notForTVSelection)
  const preReleaseSelection = useWorkbenchStore((state) => state.preReleaseSelection)
  const lengthMin = useWorkbenchStore((state) => state.lengthMin)
  const lengthMax = useWorkbenchStore((state) => state.lengthMax)

  const activeFiltersArr = []

  if (instrumentalToggledOn || vocalsToggledOn || vocalsFemaleToggledOn || vocalsMaleToggledOn) {
    activeFiltersArr.push(navigationItems["vocals"].value)
  }
  if (moodsToggledOn.length || moodsToggledOff.length) {
    activeFiltersArr.push(navigationItems["mood"].value)
  }
  if (energiesToggledOn.length) {
    activeFiltersArr.push(navigationItems["energy"].value)
  }
  if (arcsToggledOn.length) {
    activeFiltersArr.push(navigationItems["arc"].value)
  }
  if (lengthMin || lengthMax) {
    activeFiltersArr.push(navigationItems["length"].value)
  }
  if (instrumentsToggledOn.length || instrumentsToggledOff.length) {
    activeFiltersArr.push(navigationItems["instruments"].value)
  }
  if (genresToggledOn.length || genresToggledOff.length) {
    activeFiltersArr.push(navigationItems["genres"].value)
  }
  if (customizableSelection) {
    activeFiltersArr.push(navigationItems["customizable"].value)
  }
  if (sortedIndexName !== "") {
    activeFiltersArr.push(navigationItems["sort"].value)
  }
  if (
    artistCollabSelection ||
    fervorSelection ||
    forMarmosetSelection ||
    notForTVSelection ||
    preReleaseSelection ||
    flagsToggledOn.length
  ) {
    activeFiltersArr.push(navigationItems["internal"].value)
  }

  return activeFiltersArr
}
