import create from "zustand"
import { devtools } from "zustand/middleware"

export const initialState = {
  arcsToggledOn: [],
  energiesToggledOn: [],
  instrumentsToggledOn: [],
  instrumentsToggledOff: [],
  genresToggledOn: [],
  genresToggledOff: [],
  moodsToggledOn: [],
  moodsToggledOff: [],
  flagsToggledOn: [],
  instrumentalToggledOn: false,
  vocalsToggledOn: false,
  vocalsFemaleToggledOn: false,
  vocalsMaleToggledOn: false,
  artistCollabSelection: "",
  customizableSelection: "",
  fervorSelection: "",
  forMarmosetSelection: "",
  notForTVSelection: "",
  preReleaseSelection: "",
  lengthMin: undefined,
  lengthMax: undefined,
  sortedIndexName: "",
  searchQuery: "",
  selectedResultsFilter: "all",
}

export const useWorkbenchStore = create(
  devtools((set) => ({
    ...initialState,
    currentFilter: "",
    setSortedIndexName: (input) => set(() => ({ sortedIndexName: input })),
    resetSortedIndexName: () => set(() => ({ sortedIndexName: "" })),
    setCurrentFilter: (input) => set(() => ({ currentFilter: input })),
    // Todo: Change "instrumentsToggledOn" to "instrumentsRefinedOn" etc., leave ToggledOns for booleans
    setSearchQuery: (input) => set(() => ({ searchQuery: input })),
    resetSearchQuery: () => set({ searchQuery: "" }),
    setSelectedResultsFilter: (input) => set({ selectedResultsFilter: input }),
    toggleInstrumental: () =>
      set((state) => ({ instrumentalToggledOn: !state.instrumentalToggledOn })),
    toggleVocals: () => set((state) => ({ vocalsToggledOn: !state.vocalsToggledOn })),
    toggleVocalsFemale: () =>
      set((state) => ({ vocalsFemaleToggledOn: !state.vocalsFemaleToggledOn })),
    toggleVocalsMale: () => set((state) => ({ vocalsMaleToggledOn: !state.vocalsMaleToggledOn })),
    setGenresToggledOn: (input) => set(() => ({ genresToggledOn: input })),
    setGenresToggledOff: (input) => set(() => ({ genresToggledOff: input })),
    setInstrumentsToggledOn: (input) => set(() => ({ instrumentsToggledOn: input })),
    setInstrumentsToggledOff: (input) => set(() => ({ instrumentsToggledOff: input })),
    setMoodsToggledOn: (input) => set(() => ({ moodsToggledOn: input })),
    setMoodsToggledOff: (input) => set(() => ({ moodsToggledOff: input })),
    setArtistCollabSelection: (input) => set(() => ({ artistCollabSelection: input })),
    setArtistCollabSelection: (input) => set(() => ({ artistCollabSelection: input })),
    setCustomizableSelection: (input) => set(() => ({ customizableSelection: input })),
    setFervorSelection: (input) => set(() => ({ fervorSelection: input })),
    setForMarmosetSelection: (input) => set(() => ({ forMarmosetSelection: input })),
    setNotForTVSelection: (input) => set(() => ({ notForTVSelection: input })),
    setPreReleaseSelection: (input) => set(() => ({ preReleaseSelection: input })),
    setLengthMin: (input) => set({ lengthMin: input }),
    setLengthMax: (input) => set({ lengthMax: input }),
    setArcs: (input) => set({ arcsToggledOn: input }),
    setFlagsToggledOn: (input) => set({ flagsToggledOn: input }),
    setEnergies: (input) => set({ energiesToggledOn: input }),
    addToArcs: (item) => set((state) => ({ arcsToggledOn: [...state.arcsToggledOn, item] })),
    addToFlagsOn: (item) => set((state) => ({ flagsToggledOn: [...state.flagsToggledOn, item] })),
    addToEnergies: (item) =>
      set((state) => ({ energiesToggledOn: [...state.energiesToggledOn, item] })),
    addToGenresOn: (item) =>
      set((state) => ({ genresToggledOn: [...state.genresToggledOn, item] })),
    addToGenresOff: (item) =>
      set((state) => ({ genresToggledOff: [...state.genresToggledOff, item] })),
    addToInstrumentsOn: (item) =>
      set((state) => ({ instrumentsToggledOn: [...state.instrumentsToggledOn, item] })),
    addToInstrumentsOff: (item) =>
      set((state) => ({ instrumentsToggledOff: [...state.instrumentsToggledOff, item] })),
    addToMoodsOn: (item) => set((state) => ({ moodsToggledOn: [...state.moodsToggledOn, item] })),
    addToMoodsOff: (item) =>
      set((state) => ({ moodsToggledOff: [...state.moodsToggledOff, item] })),
    removeFromArcs: (item) =>
      set((state) => ({ arcsToggledOn: state.arcsToggledOn.filter((arc) => arc != item) })),
    removeFromFlagsOn: (item) =>
      set((state) => ({ flagsToggledOn: state.flagsToggledOn.filter((flag) => flag != item) })),
    removeFromEnergies: (item) =>
      set((state) => ({
        energiesToggledOn: state.energiesToggledOn.filter((energy) => energy != item),
      })),
    removeFromGenresOn: (item) =>
      set((state) => ({
        genresToggledOn: state.genresToggledOn.filter((genre) => genre != item),
      })),
    removeFromGenresOff: (item) =>
      set((state) => ({
        genresToggledOff: state.genresToggledOff.filter((genre) => genre != item),
      })),
    removeFromInstrumentsOn: (item) =>
      set((state) => ({
        instrumentsToggledOn: state.instrumentsToggledOn.filter((instrument) => instrument != item),
      })),
    removeFromInstrumentsOff: (item) =>
      set((state) => ({
        instrumentsToggledOff: state.instrumentsToggledOff.filter(
          (instrument) => instrument != item
        ),
      })),
    removeFromMoodsOn: (item) =>
      set((state) => ({
        moodsToggledOn: state.moodsToggledOn.filter((mood) => mood != item),
      })),
    removeFromMoodsOff: (item) =>
      set((state) => ({
        moodsToggledOff: state.moodsToggledOff.filter((mood) => mood != item),
      })),
    resetArtistCollab: () => set({ artistCollabSelection: "" }),
    resetCustomizable: () => set(() => ({ customizableSelection: "" })),
    resetForMarmoset: () => set({ forMarmosetSelection: "" }),
    resetFervor: () => set({ fervorSelection: "" }),
    resetGenres: () => set({ genresToggledOn: [], genresToggledOff: [] }),
    resetFlags: () => set({ flagsToggledOn: [] }),
    resetNotForTV: () => set({ notForTVSelection: "" }),
    resetPreRelease: () => set({ preReleaseSelection: "" }),
    resetLengthMin: () => set({ lengthMin: undefined }),
    resetLengthMax: () => set({ lengthMax: undefined }),
    resetAllFilters: () =>
      set((state) => ({
        ...initialState,
        searchQuery: state.searchQuery,
        sortedIndexName: state.sortedIndexName,
      })),
  }))
)
