import { useState } from "react"
import { useWorkbenchStore } from "../../store/store"
import WorkbenchFilterInfo from "./WorkbenchFilterInfo"
import WorkbenchFilterVocals from "./WorkbenchFilterVocals"
import WorkbenchFooter from "./WorkbenchFooter"
import WorkbenchSearch from "./WorkbenchSearch"
import WorkbenchNav from "./WorkbenchNav"
import WorkbenchFilterMood from "./WorkbenchFilterMood"
import WorkbenchFilterEnergy from "./WorkbenchFilterEnergy"
import WorkbenchFilterArc from "./WorkbenchFilterArc"
import WorkbenchFilterLength from "./WorkbenchFilterLength"
import WorkbenchFilterInstruments from "./WorkbenchFilterInstruments"
import WorkbenchFilterGenres from "./WorkbenchFilterGenres"
import WorkbenchFilterCustomizable from "./WorkbenchFilterCustomizable"
import WorkbenchFilterInternalBeta from "./WorkbenchFilterInternalBeta"
import WorkbenchLegend from "./WorkbenchLegend"
import cx from "classnames"
import WorkbenchSort from "./WorkbenchSort"

const WorkbenchContainer = ({ user_role }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const isInternalUser = user_role === "internal"
  const [activeTab, setActiveTab] = useState("filter")

  return (
    <section id="work-bench-beta" aria-label="Browse filters and search">
      <nav className="tabs mobile" aria-label="Filter and search tabs">
        <ul>
          <li className={cx("filter", activeTab === "filter" ? "current" : "")}>
            <a data-prevent-default onClick={() => setActiveTab("filter")}>
              Filter
            </a>
          </li>
          <li className={cx("search", activeTab === "search" ? "current" : "")}>
            <a data-prevent-default onClick={() => setActiveTab("search")}>
              Search
            </a>
          </li>
        </ul>
      </nav>

      <nav className="tabs desktop" aria-label="Browse by Search & Filters or Youtube Link">
        <ul>
          <li className="current">
            <a data-prevent-default>Search & Browse</a>
          </li>
          <li className="">
            <a data-prevent-default href="/youtube-search">
              <span className="new">Search by Youtube Link (beta)</span>
            </a>
          </li>
        </ul>
      </nav>

      <section
        className={cx(
          "contain",
          "filter-search-nav",
          "search-wrap",
          activeTab === "search" ? "current" : ""
        )}
        aria-label="Search"
      >
        <WorkbenchSearch />
      </section>

      <section
        className={cx(
          "contain",
          "filter-search-nav",
          "filter-wrap",
          activeTab === "filter" ? "current" : ""
        )}
        aria-label="Filter"
      >
        <WorkbenchNav user_role={user_role} />

        <WorkbenchFilterInfo />

        <article
          className={cx("bench", currentFilter === "" ? "closed" : "")}
          aria-label="Filter workbench"
        >
          <form className="contain">
            <WorkbenchFilterVocals id="vocals" />
            <WorkbenchFilterMood id="mood" />
            <WorkbenchFilterEnergy id="energy" />
            <WorkbenchFilterArc id="arc" />
            <WorkbenchFilterLength id="length" />
            <WorkbenchFilterInstruments id="instruments" />
            <WorkbenchFilterGenres id="genres" />
            <WorkbenchFilterCustomizable id="customizable" />
            {isInternalUser && <WorkbenchFilterInternalBeta id="internal" />}
            <WorkbenchSort id="sort" />
          </form>
        </article>
      </section>
      {currentFilter === "internal" && <WorkbenchLegend user_role={user_role} />}
      <WorkbenchFooter />
    </section>
  )
}

export default WorkbenchContainer
